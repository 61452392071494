<script setup>
import { defineExpose, defineEmits, defineProps, computed, reactive } from 'vue';
import DropdownAtividadeEspecifica from '../../../../../atividade-especifica/components/DropdownAtividadeEspecifica';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

const emit = defineEmits(['update:formData']);

const props = defineProps({
    formData: Object
});

const _form = computed({
    get: () => props.formData,
    set: (value) => emit('update:formData', value)
});

const rules = {
    atividadeEspecificaSelecionada: {
        required: helpers.withMessage('Campo obrigatório.', required),
        lazy: true
    }
};

const v$ = useVuelidate(rules, _form);
const vuelidateCommands = reactive({
    validar: () => v$.value.$validate(),
    resetar: () => v$.value.$reset()
});

defineExpose({
    vuelidateCommands
});
</script>
<template>
    <div class="grid">
        <div class="col-12 mt-1">
            <DropdownAtividadeEspecifica
                v-model="v$.atividadeEspecificaSelecionada.$model"
                class="w-full"
                :class="{ 'p-invalid': v$.atividadeEspecificaSelecionada.$errors.length && v$.atividadeEspecificaSelecionada.lazy.$dirty }"
            />
            <small v-if="v$.atividadeEspecificaSelecionada.$errors.length && v$.atividadeEspecificaSelecionada.lazy.$dirty" class="p-error">
                {{ v$.atividadeEspecificaSelecionada.$errors[0].$message }}
            </small>
        </div>
        <div class="field-checkbox col-12">
            <InputSwitch id="aplicaRisco" class="mr-2" v-model="_form.aplicaRisco" />
            <label for="aplicaRisco">Aplica risco</label>
        </div>
    </div>
</template>
