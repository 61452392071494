<script setup>
import { ref, reactive, defineProps, defineExpose, defineEmits, watch, computed } from 'vue';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { GrupoRiscosLabelEnum } from '../../../../../../enums/GrupoRiscos';
import { StatusGroGrupoRiscosElaboracaoEnum, styleMapStatusGrupoRiscos } from '../../../../../../enums/StatusGroGrupoRiscosElaboracao';
import DropdownRiscos from '../../../form-caracterizacao/DropdownRiscos.vue';
import StatusGrid from '../../../StatusGrid.vue';

const emit = defineEmits(['update:formData']);

const props = defineProps({
    formData: Object
});

const _form = computed({
    get: () => props.formData,
    set: (value) => emit('update:formData', value)
});

const rules = {
    fatorRisco: {
        required: helpers.withMessage('O Fator de risco é obrigatório.', required),
        lazy: true
    },
    dataInicial: {
        required: helpers.withMessage('A Data inicial é obrigatória.', required),
        lazy: true
    },
    dataFinal: {
        lazy: true,
        required: helpers.withMessage(
            'A Data final é obrigatória.',
            requiredIf(() => !_form.value?.exposicaoAtiva)
        )
    },
    exposicaoAtiva: { default: false }
};

const statusGrupoRiscosOpcoes = ref([]);

const desabilitarDataFinal = computed(() => !!_form.value.exposicaoAtiva);

watch(
    () => _form.value.exposicaoAtiva,
    () => {
        if (_form.value.exposicaoAtiva) {
            _form.value.dataFinal = null;
        }
    }
);

watch(
    () => _form.value.dataInicial,
    () => {
        _form.value.dataFinal = null;
    }
);

const labelEnumRisco = GrupoRiscosLabelEnum;

const v$ = useVuelidate(rules, _form);
const vuelidateCommands = reactive({
    validar: () => v$.value.$validate(),
    resetar: () => v$.value.$reset()
});

const getStatusGrupoRiscosOpcoes = () => {
    statusGrupoRiscosOpcoes.value = Object.keys(StatusGroGrupoRiscosElaboracaoEnum).map(function (type) {
        return {
            label: `${StatusGroGrupoRiscosElaboracaoEnum[type]}`,
            value: `${type}`,
            style: styleMapStatusGrupoRiscos[StatusGroGrupoRiscosElaboracaoEnum[type]]
        };
    });
};

const getRiscosComTodasAsConfigs = (status) => statusGrupoRiscosOpcoes.value.find((p) => p.label === status);

getStatusGrupoRiscosOpcoes();

defineExpose({
    vuelidateCommands
});
</script>
<template>
    <div>
        <div class="grid">
            <div class="field col-8 mb-0">
                <label for="dropdownRiscos">Perigo / Fator de risco</label>
                <DropdownRiscos
                    id="dropdownRiscos"
                    v-model="v$.fatorRisco.$model"
                    class="w-full"
                    :class="{ 'p-invalid': v$.fatorRisco.$errors.length && v$.fatorRisco.lazy.$dirty }"
                />
                <small v-if="v$.fatorRisco.$errors.length && v$.fatorRisco.lazy.$dirty" class="p-error">
                    {{ v$.fatorRisco.$errors[0].$message }}
                </small>
            </div>
            <div class="field col-4 mb-0">
                <label for="dropdownRiscos">Grupo</label>
                <StatusGrid
                    v-if="_form.fatorRisco?.grupoRisco && getRiscosComTodasAsConfigs(labelEnumRisco.get(_form.fatorRisco?.grupoRisco))"
                    class="w-full"
                    :status="getRiscosComTodasAsConfigs(labelEnumRisco.get(_form.fatorRisco?.grupoRisco))"
                />
                <div v-if="!_form.fatorRisco?.grupoRisco" class="w-full"><strong>Não informado</strong></div>
            </div>
            <div class="field col-6">
                <label for="dataInicio">Data inicial</label>
                <Calendar
                    showIcon
                    id="dataInicio"
                    v-model="v$.dataInicial.$model"
                    :class="{ 'p-invalid': v$.dataInicial.$errors.length && v$.dataInicial.lazy.$dirty }"
                    :maxDate="_form.dataFim"
                    :disabledDays="[0, 6]"
                    class="w-full"
                    dateFormat="dd/mm/yy"
                />
                <small v-if="v$.dataInicial.$errors.length && v$.dataInicial.lazy.$dirty" class="p-error">
                    {{ v$.dataInicial.$errors[0].$message }}
                </small>
            </div>
            <div class="field col-6">
                <label for="dataFim">Data final</label>
                <Calendar
                    showIcon
                    id="dataFim"
                    :minDate="_form.dataInicial"
                    v-model="v$.dataFinal.$model"
                    :disabledDays="[0, 6]"
                    class="w-full"
                    dateFormat="dd/mm/yy"
                    :disabled="desabilitarDataFinal"
                    :class="{ 'p-invalid': v$.dataFinal.$errors.length && v$.dataFinal.lazy.$dirty }"
                />
                <small v-if="v$.dataFinal.$errors.length && v$.dataFinal.lazy.$dirty" class="p-error">
                    {{ v$.dataFinal.$errors[0].$message }}
                </small>
            </div>
        </div>
        <div class="flex flex-row align-items-start justify-content-end">
            <Checkbox class="field-checkbox" v-model="v$.exposicaoAtiva.$model" inputId="exposicaoAtiva" binary name="exposicaoAtiva" :value="true" />
            <label for="exposicaoAtiva" class="ml-2"> Exposição ativa </label>
        </div>
    </div>
</template>
<style scoped lang="scss"></style>
