<template>
    <AppMultiselect v-bind="$attrs" v-model="value" :service="$serviceFuncionario" optionLabel="name" />
</template>
<script>
import SesmtService from '../../../../services/SesmtService';

export default {
    name: 'MultiselectFuncionario',
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        hierarquiaId: {
            type: Number
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $serviceFuncionario: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    created() {
        this.$serviceFuncionario = new SesmtService(
            `/aplicacao-pgr-elaboracao/find-functionarios-e-riscos-aplicados/hierarquia/${this.hierarquiaId}/inventario-hierarquia/0`
        );
    }
};
</script>
