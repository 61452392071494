<script setup>
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useAlert } from '../../composables/useAlert';
import { getClientSesmt } from '../../services/http-sesmt';
import { StatusGroGrupoRiscosElaboracaoEnum } from '../../enums/StatusGroGrupoRiscosElaboracao';
import StatusGrid from './components/StatusGrid';
import SesmtService from '../../services/SesmtService';
import StatusGroHierarquiasElaboracaoEnum from '../../enums/StatusGroHierarquiasElaboracao';
import HeaderPanel from './components/HeaderPanel';
import FiltroInventarioRisco from './components/inventario-risco/FiltroInventarioRisco.vue';
import DialogAplicacao from './components/inventario-risco/dialog-aplicacao/DialogAplicacao.vue';
import StatusGroQuestionariosElaboracaoEnum from '../../enums/StatusGroQuestionariosElaboracao';
import DialogVerFuncionariosComRiscos from './components/inventario-risco/DialogVerFuncionariosComRiscos.vue';
import DialogDetalhesHierarquia from './components/DialogDetalhesHierarquia.vue';
import TabelaRiscos from './components/inventario-risco/TabelaRiscos.vue';
import TabelaAtividadeEspecifica from './components/inventario-risco/TabelaAtividadeEspecifica.vue';

const { currentRoute, replace } = useRouter();
const { alertError } = useAlert();
const customer = ref({});
const filtrosDoComponente = ref({});
const store = useStore();
const loading = ref(false);
const loadingRespostaPerigo = ref();
const loadingAplicAtividadesEspecificas = ref(false);
const queryParams = ref({});
const records = ref();
const dialogAplicacao = ref();
const page = ref(1);
const recordsPerPage = ref(10);
const totalRecords = ref(0);
const totalPages = ref(0);
const selectedSetor = ref([]);
const expandedRows = ref();
const statusHierarquiasElaboracaoOpcoes = ref([]);
const statusGrupoRiscosElaboracaoOpcoes = ref([]);
const statusCaracterizacaoRiscoOpcoes = ref([]);
const dialogVerFuncionariosComRiscos = ref();

const openDialogVerFuncionariosComRiscos = ({ hierarquia, customer }) => dialogVerFuncionariosComRiscos.value?.openDialog({ hierarquia, customer });
const detalhesHierarquias = ref();
const dialogDetalhesHierarquia = ref();

const openDialogAplicacao = (data, indexHierarquia) => dialogAplicacao.value.openDialog(data, indexHierarquia);

const styleMapStatusGrupoRiscos = {
    [StatusGroGrupoRiscosElaboracaoEnum.FISICO]: {
        backgroundColor: '#FFF',
        color: '#419544'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.QUIMICO]: {
        backgroundColor: '#FFF',
        color: '#C61A54'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.BIOLOGICO]: {
        backgroundColor: '#FFF',
        color: '#D06900'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.ERGONOMICO]: {
        backgroundColor: '#FFF',
        color: '#D5A326'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.INESPECIFICO]: {
        backgroundColor: '#FFF',
        color: '#852196'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.OUTRO]: {
        backgroundColor: '#FFF',
        color: '#653E11'
    },
    [StatusGroGrupoRiscosElaboracaoEnum.ACIDENTE_MECANICO]: {
        backgroundColor: '#FFF',
        color: '#1C80CF'
    }
};

const styleMapStatus = {
    [StatusGroHierarquiasElaboracaoEnum.PENDENTE]: {
        backgroundColor: '#F6DFC3',
        color: '#653E11'
    },
    [StatusGroHierarquiasElaboracaoEnum.SEM_APLICACAO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroHierarquiasElaboracaoEnum.VALIDACAO_EM_ANDAMENTO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroHierarquiasElaboracaoEnum.PENDENTE_AVALIADOR]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroHierarquiasElaboracaoEnum.VALIDADO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroHierarquiasElaboracaoEnum.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

const styleMapStatusCaracterizacaoRisco = {
    [StatusGroQuestionariosElaboracaoEnum.PENDENTE]: {
        backgroundColor: '#F6DFC3',
        color: '#653E11'
    },
    [StatusGroQuestionariosElaboracaoEnum.AGUARDANDO_RETORNO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },

    [StatusGroQuestionariosElaboracaoEnum.VALIDADO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroQuestionariosElaboracaoEnum.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

const onPage = async (event) => {
    page.value = event.page + 1;
    recordsPerPage.value = event.rows;
    await load();
};

const aplicarFiltros = async (removerFiltros = false) => {
    if (removerFiltros) {
        filtrosDoComponente.value = {};
        atualizarQueryParamsStore({});
    }

    await load();
};

const atualizarQueryParams = async () => {
    const { setor, cargo, statusGro } = filtrosDoComponente.value;

    atualizarQueryParamsStore({
        setorId: setor?.id ? JSON.stringify(setor.id) : undefined,
        cargoId: cargo?.id ? JSON.stringify(cargo.id) : undefined,
        statusGro: statusGro ? JSON.stringify(statusGro) : undefined
    });

    await replace({
        query: {
            setorId: setor?.id ? JSON.stringify(setor.id) : undefined,
            cargoId: cargo?.id ? JSON.stringify(cargo.id) : undefined,
            statusGro: statusGro ? JSON.stringify(statusGro) : undefined
        }
    });
};

const atualizarQueryParamsStore = (params) => store.dispatch('setQueryParamsTelaInventarioRiscoGro', params);

const getStatusHierarquiasElaboracaoOpcoes = () => {
    statusHierarquiasElaboracaoOpcoes.value = Object.keys(StatusGroHierarquiasElaboracaoEnum).map(function (type) {
        return {
            label: `${StatusGroHierarquiasElaboracaoEnum[type]}`,
            value: `${type}`,
            style: styleMapStatus[StatusGroHierarquiasElaboracaoEnum[type]]
        };
    });
};

const getStatusGrupoRiscosElaboracaoOpcoes = () => {
    statusGrupoRiscosElaboracaoOpcoes.value = Object.keys(StatusGroGrupoRiscosElaboracaoEnum).map(function (type) {
        return {
            label: `${StatusGroGrupoRiscosElaboracaoEnum[type]}`,
            value: `${type}`,
            style: styleMapStatusGrupoRiscos[StatusGroGrupoRiscosElaboracaoEnum[type]]
        };
    });
};

const getStatusCaracterizacaoRiscoElaboracaoOpcoes = () => {
    statusCaracterizacaoRiscoOpcoes.value = Object.keys(StatusGroQuestionariosElaboracaoEnum).map(function (type) {
        return {
            label: `${StatusGroQuestionariosElaboracaoEnum[type]}`,
            value: `${type}`,
            style: styleMapStatusCaracterizacaoRisco[StatusGroQuestionariosElaboracaoEnum[type]]
        };
    });
};

const getStatusComTodasAsConfigs = (status) => statusHierarquiasElaboracaoOpcoes.value.find((p) => p.value === status);

const getCustomer = async (customerId) => {
    const { data } = await new SesmtService('/customers').findById(customerId);
    customer.value = data;
};

const dataTableKey = ref(0);
const load = async () => {
    try {
        loading.value = true;
        const visitaTecnicaId = currentRoute.value.params.visitaTecnicaId;
        const unidadeId = currentRoute.value.params.idUnidade;
        const { data } = await getClientSesmt().get(
            `aplicacao-pgr-elaboracao/find-hierarquias/unidade/${unidadeId}/visita-tecnica/${visitaTecnicaId}`,
            {
                params: {
                    limit: recordsPerPage.value,
                    page: page.value,
                    filtrosExtras: {
                        setorId: filtrosDoComponente.value?.setor?.id,
                        cargoId: filtrosDoComponente.value?.cargo?.id,
                        statusGro: filtrosDoComponente.value?.statusGro
                    }
                }
            }
        );
        records.value = data.items;
        totalRecords.value = data.meta?.totalItems;
        totalPages.value = data.meta?.totalPages;
    } catch (error) {
        if (error?.response?.data?.message) {
            const message = error?.response?.data?.message;
            alertError(`Erro ao buscar! ${message}`);
        }
    } finally {
        loading.value = false;
        dataTableKey.value++;
    }
};

const loadRespostaPerigoPorHierarquia = async (event) => {
    if (!event.data?.inventarioHierarquiaId) return;

    try {
        event.data.respostasPerigos = [];
        loadingRespostaPerigo.value = event.data?.inventarioHierarquiaId;

        const { data } = await getClientSesmt().get(
            `aplicacao-pgr-elaboracao/find-aplicacao-respostas-perigos/inventario-hierarquia/${event.data?.inventarioHierarquiaId}`
        );

        event.data.respostasPerigos = data;
    } catch (error) {
        const { message } = error?.response?.data;
        alertError(`Erro ao listar! ${message}`);
    } finally {
        setStatusHierarquia(event.indexHierarquia, event.data.respostasPerigos);
        loadingRespostaPerigo.value = undefined;
    }
};

const loadAplicAtividadesEspecificasFuncionarios = async (event) => {
    try {
        loadingAplicAtividadesEspecificas.value = true;
        const { data } = await getClientSesmt().get(
            `aplicacao-pgr-elaboracao/find-aplicacao-atividades-especifica/hierarquia/${event.data.hierarquia.id}/visita-tecnica/${currentRoute.value.params.visitaTecnicaId}`
        );
        event.data.aplicAtividadesEspecificas = data;
    } catch (error) {
        if (error?.response?.data?.message) {
            const message = error?.response?.data?.message;
            alertError(`Erro ao buscar! ${message}`);
        }
    } finally {
        loadingAplicAtividadesEspecificas.value = false;
    }
};

const recarregarTabelas = async (event) => {
    await load();
    await loadRespostaPerigoPorHierarquia(event);
    await loadAplicAtividadesEspecificasFuncionarios(event);
};

const setStatusHierarquia = (indexHierarquia, respostasPerigos) => {
    const hasPendente = respostasPerigos.some((exposicao) => exposicao.statusCaracterizacao === StatusGroQuestionariosElaboracaoEnum.PENDENTE);

    if (!records.value?.[indexHierarquia]?.hierarquia?.statusGro) return;

    const getNovoStatus = (valorStatus) => statusHierarquiasElaboracaoOpcoes.value.find((p) => p.label === valorStatus).value;

    if (hasPendente) {
        records.value[indexHierarquia].hierarquia.statusGro = getNovoStatus(StatusGroHierarquiasElaboracaoEnum.PENDENTE);
        return;
    }

    records.value[indexHierarquia].hierarquia.statusGro = getNovoStatus(StatusGroHierarquiasElaboracaoEnum.VALIDADO);
};

const init = async () => {
    const routeQueryParams = currentRoute.value?.query;
    queryParams.value = Object.keys(routeQueryParams).length ? routeQueryParams : store.getters.queryParamsTelaAmbienteGro;
    if (Object.keys(queryParams.value).length) {
        await atualizarQueryParams();
    } else {
        filtrosDoComponente.value = {
            name: queryParams.value?.name,
            setorId: queryParams.value?.setorId,
            cargoId: queryParams.value?.cargoId,
            statusGro: queryParams.value?.statusGro
        };

        await atualizarQueryParams();
    }

    const customerId = currentRoute.value?.params?.id ? Number(currentRoute.value?.params?.id) : 0;
    getStatusHierarquiasElaboracaoOpcoes();
    getStatusGrupoRiscosElaboracaoOpcoes();
    getStatusCaracterizacaoRiscoElaboracaoOpcoes();
    await getCustomer(customerId);
    await load();
};

const onTabOpen = async (event, indexHierarquia, hierarquia) => {
    const index = event.index;
    const payload = {
        data: hierarquia,
        indexHierarquia
    };

    switch (index) {
        case 0:
            await loadRespostaPerigoPorHierarquia(payload);
            break;
        case 1:
            await loadAplicAtividadesEspecificasFuncionarios(payload);
            break;
        default:
            break;
    }
};

const abrirDetalhesHierarquia = async (payload) => {
    const { data } = await getClientSesmt().get(`customer/hierarchies/get-descicao/setor/cargo/e-cargo-hierarquia`, {
        params: {
            hierarquiaId: payload.hierarquia.id,
            setorId: payload.hierarquia.customerDepartment.id,
            cargoId: payload.hierarquia.customerPosition.id
        }
    });
    detalhesHierarquias.value = data;
    dialogDetalhesHierarquia.value.abrir({
        ...detalhesHierarquias.value,
        hierarquiaId: payload.hierarquia.id,
        setorId: payload.hierarquia.customerDepartment.id,
        cargoId: payload.hierarquia.customerPosition.id
    });
};

onBeforeMount(init);
</script>
<template>
    <div class="card">
        <HeaderPanel tooltip="Inventário de risco" :title="'Inventário de risco'" nomeTelaDoManual="customer-inventario-risco-gro">
            <template #extraContent>
                <div class="flex flex-row justify-content-center align-items-center gap-2 identificacao-customer">
                    <i class="pi pi-building text-4xl"></i>
                    <div class="flex flex-column">
                        <span class="font-semibold line-height-3 cursor-pointer nome-cliente" v-tooltip.bottom="customer?.name">{{
                            customer?.name
                        }}</span>
                        <span class="">Cód. SOC - {{ customer?.code }}</span>
                    </div>
                </div>
            </template>
        </HeaderPanel>
        <FiltroInventarioRisco v-model:filtrosExtras="filtrosDoComponente" @loadInventarioRisco="aplicarFiltros" />

        <DataTable
            :key="dataTableKey"
            v-model:expandedRows="expandedRows"
            v-model:selection="selectedSetor"
            :value="records"
            dataKey="hierarquia.id"
            tableStyle="min-width: 50rem"
            size="large"
            :loading="loading"
            :pt="{
                rowExpansionCell: {
                    class: ['p-0 pl-5']
                }
            }"
        >
            <template #empty> Nenhum registro encontrado.</template>
            <template #loading>
                <AppLoadingWrapper />
            </template>
            <Column field="hierarquia.id" headerStyle="display:none" bodyStyle="background-color:#EDEDED" bodyClass="border-top-1 border-300">
                <template #body="{ data, index }">
                    <div class="column-header w-full flex justify-content-between">
                        <div class="flex flex-row">
                            <div class="flex align-items-center mr-2">
                                <Button
                                    icon="pi pi-book"
                                    severity="secondary"
                                    text
                                    rounded
                                    aria-label="Filter"
                                    class="p-button-lg"
                                    @click="abrirDetalhesHierarquia(data)"
                                />
                            </div>
                            <div class="header-description flex flex-column">
                                <strong class="title">{{ data?.hierarquia.customerDepartment.name }}</strong>
                                <span class="description">{{ data?.hierarquia.customerPosition.name }}</span>
                            </div>
                        </div>
                        <div class="flex align-items-center gap-6">
                            <div>
                                <Button
                                    class="button-salvar"
                                    text
                                    :loading="loading"
                                    label="Ver funcionários"
                                    @click="openDialogVerFuncionariosComRiscos({ hierarquia: data, customer: customer })"
                                />
                            </div>
                            <div>
                                <Button
                                    icon="pi pi-plus"
                                    class="button-salvar"
                                    type="button"
                                    :loading="loading"
                                    label="Adicionar"
                                    @click="openDialogAplicacao(data, index)"
                                />
                            </div>
                            <div class="status">
                                <StatusGrid v-if="data?.hierarquia.statusGro" :status="getStatusComTodasAsConfigs(data?.hierarquia.statusGro)" />
                            </div>
                        </div>
                    </div>
                </template>
            </Column>
            <Column
                expander
                style="width: 5rem"
                headerStyle="display:none"
                bodyStyle="background-color:#EDEDED"
                bodyClass="border-top-1 border-300"
            />
            <template #expansion="{ data: hierarquia, index: indexHierarquia }">
                <Accordion :multiple="true" :activeIndex="0" @tab-open="onTabOpen($event, indexHierarquia, hierarquia)">
                    <AccordionTab
                        header="Riscos"
                        :pt="{
                            content: { class: ['p-0'] },
                            headerAction: { style: ['background-color:#EDEDED'], class: [' flex flex-row-reverse justify-content-between '] }
                        }"
                    >
                        <TabelaRiscos
                            :loading="hierarquia.inventarioHierarquiaId === loadingRespostaPerigo"
                            :respostasPerigos="hierarquia.respostasPerigos"
                            :inventarioHierarquiaId="hierarquia.inventarioHierarquiaId"
                            :statusGrupoRiscosElaboracaoOpcoes="statusGrupoRiscosElaboracaoOpcoes"
                            :statusCaracterizacaoRiscoOpcoes="statusCaracterizacaoRiscoOpcoes"
                            :hierarquia="hierarquia"
                            @onSuccessExcluirExposicao="recarregarTabelas"
                        />
                    </AccordionTab>
                    <AccordionTab
                        header="Atividade específica"
                        :pt="{
                            content: { class: ['p-0'] },
                            headerAction: { style: ['background-color:#EDEDED'], class: [' flex flex-row-reverse justify-content-between '] }
                        }"
                    >
                        <TabelaAtividadeEspecifica
                            :loading="loadingAplicAtividadesEspecificas"
                            :aplicAtividadesEspecificas="hierarquia.aplicAtividadesEspecificas"
                            :inventarioHierarquiaId="hierarquia.inventarioHierarquiaId"
                            :hierarquia="hierarquia"
                            :indexHierarquia="indexHierarquia"
                            @onCarregarListagem="recarregarTabelas"
                        />
                    </AccordionTab>
                </Accordion>
            </template>
        </DataTable>
        <Paginator
            v-show="!loading"
            :rows="recordsPerPage"
            :totalRecords="totalRecords"
            :rowsPerPageOptions="[10, 50, 100]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
            @page="onPage"
        >
        </Paginator>
        <DialogVerFuncionariosComRiscos ref="dialogVerFuncionariosComRiscos" />
        <DialogAplicacao @onSuccessExposicao="recarregarTabelas" ref="dialogAplicacao" />
        <DialogDetalhesHierarquia ref="dialogDetalhesHierarquia" />
    </div>
</template>
