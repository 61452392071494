<script setup>
import { defineEmits, defineProps } from 'vue';
import { useFiltrosInventarioRisco } from '../../composables/useFiltrosInventarioRisco';
import { useRouter } from 'vue-router';

import InfinityDropdownCargoByHierarquia from './InfinityDropdownCargoByHierarquia.vue';
import InfinityDropdownSetorByHierarquia from './InfinityDropdownSetorByHierarquia.vue';

const emit = defineEmits(['loadInventarioRisco', 'update:filtrosExtras']);
const props = defineProps({
    filtrosExtras: Object
});

const { currentRoute } = useRouter();
const unidadeId = currentRoute.value.params.idUnidade;
const customerId = currentRoute.value?.params?.id ? Number(currentRoute.value?.params?.id) : 0;

const optionsStatus = [
    { label: 'Sem dados', value: 'SEM_DADOS' },
    { label: 'Pendente', value: 'PENDENTE' },
    { label: 'Validado', value: 'VALIDADO' },
    { label: 'Concluído', value: 'CONCLUIDO' }
];
const { _filtrosExtras, filtrar, removerFiltros } = useFiltrosInventarioRisco(props, emit);
</script>
<template>
    <div class="flex flex-row align-items-center">
        <div class="flex-1 grid">
            <div class="field col-12 md:col-3">
                <label class="mb-2" for="setor">Setor</label>
                <InfinityDropdownSetorByHierarquia
                    id="customerDepartments"
                    :loading="loadingHierarquia"
                    class="w-full"
                    v-model="_filtrosExtras.setor"
                    :branchId="unidadeId"
                    :customerId="customerId"
                    showClear
                />
            </div>
            <div class="field col-12 md:col-3">
                <label class="mb-2" for="cargo">Cargo</label>
                <InfinityDropdownCargoByHierarquia
                    id="customerPositionId"
                    :loading="loadingHierarquia"
                    class="w-full"
                    :customerId="customerId"
                    :branchId="unidadeId"
                    v-model="_filtrosExtras.cargo"
                    minWidth="500px"
                    showClear
                />
            </div>
            <div class="field col-12 md:col-3">
                <label class="mb-2" for="status">Status</label>
                <Dropdown
                    v-model="_filtrosExtras.statusGro"
                    :options="optionsStatus"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Selecionar"
                    checkmark
                    :highlightOnSelect="false"
                    class="w-full"
                    showClear
                    filter
                />
            </div>
            <div class="field col-12 md:col-3 align-self-end">
                <Button label="Remover filtros" icon="pi pi-filter-slash" class="p-button-link" @click="removerFiltros" />
                <Button label="Filtrar" icon="pi pi-filter" class="p-button-outlined" @click="filtrar" />
            </div>
        </div>
    </div>
</template>
