<script setup>
import { ref, defineEmits, defineExpose, computed } from 'vue';
import AdicionarAtividadeEspecifica from './components/AdicionarAtividadeEspecifica.vue';
import AdicionarExposicao from './components/AdicionarExposicao.vue';
import EscolherTipoAplicacao from './components/EscolherTipoAplicacao.vue';
import { getClientSesmt } from '../../../../../services/http-sesmt';
import { useAlert } from '../../../../../composables/useAlert';
import { useRouter } from 'vue-router';
import EtapasInventarioRiscoEnum from '../../../../../enums/EtapasInventarioRiscoEnum';

const emit = defineEmits(['update:formData', 'onClose', 'onSuccessExposicao']);

const isOpen = ref(false);
const etapa = ref(1);
const indexHierarquia = ref();

const form = ref({
    tipoAplicacao: undefined,
    aplicaRisco: false
});

const dadosHierarquiaGrid = ref();

const openDialog = (hierarquiaRecebida, indexExpandHierarquia) => {
    indexHierarquia.value = indexExpandHierarquia;
    dadosHierarquiaGrid.value = hierarquiaRecebida;
    isOpen.value = true;
};

const closeDialog = async () => {
    isOpen.value = false;
    etapa.value = 1;
    form.value = {
        tipoAplicacao: undefined,
        aplicaRisco: false
    };
};

const irParaProximaEtapa = async () => {
    if (form.value.tipoAplicacao === 'atividade' && form.value.aplicaRisco) {
        const isValidVuelidate = await componenteAtual.value.vuelidateCommands.validar();
        const isValidDuplicada = await validarAtividadeEspecificaDuplicada();
        if (!isValidVuelidate || !isValidDuplicada) return;
    }

    etapa.value = form.value.tipoAplicacao === 'atividade' && !form.value.aplicaRisco ? 2 : 3;
};

const validarAtividadeEspecificaDuplicada = async () => {
    try {
        return await getClientSesmt().get('aplicacao-pgr-elaboracao/validar/atividade-especifica-duplicada', {
            params: {
                atividadeEspecificaId: form.value.atividadeEspecificaSelecionada.id,
                hierarquiaId: dadosHierarquiaGrid.value.hierarquia.id,
                inventarioRiscoVisitaTecnicaId: visitaTecnicaId
            }
        });
    } catch (error) {
        alertErrorData(error);
    }
};

const titleMap = computed(() => {
    return {
        1: 'Adicionar',
        2: 'Nova atividade específica',
        3: 'Nova exposição na hierarquia'
    };
});

const componenteAtual = ref();
const componentsMap = {
    1: EscolherTipoAplicacao,
    2: AdicionarAtividadeEspecifica,
    3: AdicionarExposicao
};

async function salvar() {
    if (form.value.tipoAplicacao === 'exposicao') {
        await salvarExposicao();
        return;
    }

    if (form.value.aplicaRisco) {
        const isValid = await componenteAtual.value.vuelidateCommands.validar();
        if (!isValid) return;
    }

    await salvarAtividadeEspecifica();
}

const { currentRoute } = useRouter();
const { alertSucess, alertErrorData } = useAlert();
const isLoading = ref(false);

const customerId = currentRoute.value?.params?.id;
const visitaTecnicaId = currentRoute.value.params.visitaTecnicaId;

async function salvarExposicao() {
    try {
        const isValid = await componenteAtual.value.vuelidateCommands.validar();
        if (!isValid) return;

        isLoading.value = true;

        const response = await getClientSesmt().post('aplicacao-pgr-elaboracao/caracterizacao/nova-exposicao', montarNovaExposicaoRiscoDto());

        dadosHierarquiaGrid.value.inventarioHierarquiaId = response.data.inventarioHierarquiaId;

        const eventHierarquia = {
            data: dadosHierarquiaGrid.value,
            indexHierarquia: indexHierarquia.value
        };

        emit('onSuccessExposicao', eventHierarquia);
        alertSucess('Exposição salva com sucesso.');
        closeDialog();
    } catch (error) {
        alertErrorData(error, 'Não foi possível salvar registro, por favor tente novamente.');
    } finally {
        isLoading.value = false;
    }
}

async function salvarAtividadeEspecifica() {
    try {
        const isValid = await componenteAtual.value.vuelidateCommands.validar();
        if (!isValid) return;

        const response = await getClientSesmt().post(
            'aplicacao-pgr-elaboracao/caracterizacao/nova-atividade-especifica',
            montarAplicacaoAtividadeEspecificaDto()
        );

        dadosHierarquiaGrid.value.inventarioHierarquiaId =
            form.value.tipoAplicacao === 'atividade' && form.value.aplicaRisco
                ? response.data.aplicacaoRespostaPerigo.inventarioHierarquiaId
                : dadosHierarquiaGrid.value.inventarioHierarquiaId;

        const eventHierarquia = {
            data: dadosHierarquiaGrid.value,
            indexHierarquia: indexHierarquia.value
        };

        emit('onSuccessExposicao', eventHierarquia);
        alertSucess('Exposição salva com sucesso.');
        closeDialog();
    } catch (error) {
        alertErrorData(error, 'Não foi possível salvar registro, por favor tente novamente.');
    } finally {
        isLoading.value = false;
    }
}

function montarAplicacaoAtividadeEspecificaDto() {
    return {
        atividadeEspecificaId: form.value.atividadeEspecificaSelecionada.id,
        hierarquiaId: dadosHierarquiaGrid.value?.hierarquia.id,
        inventarioRiscoVisitaTecnicaId: visitaTecnicaId,
        etapa: EtapasInventarioRiscoEnum.PGR,
        aplicaRisco: form.value.aplicaRisco,
        exposicaoRisco: form.value.tipoAplicacao === 'atividade' && form.value.aplicaRisco ? montarNovaExposicaoRiscoDto() : null
    };
}

function montarNovaExposicaoRiscoDto() {
    return {
        inventarioRiscoVisitaTecnicaId: visitaTecnicaId,
        inventarioHierarquiaId: dadosHierarquiaGrid.value?.inventarioHierarquiaId,
        clienteId: customerId,
        hierarquiaId: dadosHierarquiaGrid.value?.hierarquia.id,
        riscoId: form.value.fatorRisco.id,
        tipoAvaliacaoRisco: form.value.fatorRisco.tipoAvaliacao,
        exposicaoAtiva: form.value.exposicaoAtiva,
        dataInicio: form.value.dataInicial,
        dataFim: form.value.dataFinal,
        etapa: EtapasInventarioRiscoEnum.PGR
    };
}

defineExpose({
    openDialog
});
</script>
<template>
    <Dialog v-model:visible="isOpen" :style="{ width: '450px' }" :header="titleMap[etapa]" @hide="closeDialog" modal>
        <component
            :is="componentsMap[etapa]"
            v-model:formData="form"
            @onClose="closeDialog"
            @onProximaEtapa="irParaProximaEtapa"
            ref="componenteAtual"
        />

        <template #footer>
            <div class="flex justify-content-end align-items-center mt-3">
                <Button class="button-link" @click="closeDialog" text label="Cancelar" />
                <Button
                    v-if="etapa === 1 || (etapa === 2 && form.aplicaRisco)"
                    class="ml-5"
                    type="button"
                    label="Próximo"
                    :disabled="!form.tipoAplicacao"
                    @click="irParaProximaEtapa"
                />
                <Button v-else class="ml-5" type="button" label="Salvar" :loading="isLoading" @click="salvar" />
            </div>
        </template>
    </Dialog>
</template>
<style scoped lang="scss"></style>
