<script setup>
import { ref, defineExpose, defineEmits } from 'vue';
import { getClientSesmt } from '../../../../services/http-sesmt';
import { useAlert } from '../../../../composables/useAlert';
import { useRouter } from 'vue-router';

const emit = defineEmits(['onSuccessExcluirExposicao']);
const router = useRouter();
const { alertSucess, alertErrorData } = useAlert();

const isOpen = ref(false);
const idExposicao = ref();
const isLoading = ref(false);
const hierarquia = ref();

const indexHierarquia = ref();
const openDialog = async ({ id, hierarquiaRecebida }, indexExpandHierarquia) => {
    indexHierarquia.value = indexExpandHierarquia;
    idExposicao.value = id;
    hierarquia.value = hierarquiaRecebida;
    isOpen.value = true;
};

const closeDialog = () => {
    isOpen.value = false;
};

const excluir = async () => {
    try {
        isLoading.value = true;
        const pgrElaboracaoId = router.currentRoute.value.params.idElaboracao;
        await getClientSesmt().delete(
            `aplicacao-pgr-elaboracao/${pgrElaboracaoId}/inventario-hierarquia/${hierarquia.value.inventarioHierarquiaId}/caracterizacao/todos-vinculos/${idExposicao.value}`
        );

        const eventHierarquia = {
            data: hierarquia.value,
            indexHierarquia: indexHierarquia.value
        };

        emit('onSuccessExcluirExposicao', eventHierarquia);
        alertSucess('Exposição excluida com sucesso.');
        closeDialog();
    } catch (error) {
        if (error?.response?.data?.details?.response?.retornoErrosCadastro?.contemErrosCadastro) {
            closeDialog();
            return;
        }

        alertErrorData(error, 'Não foi possível excluir registro, por favor tente novamente.');
    } finally {
        isLoading.value = false;
    }
};

defineExpose({
    openDialog
});
</script>
<template>
    <Dialog
        v-model:visible="isOpen"
        :style="{ width: '450px' }"
        header="Excluir exposição"
        @hide="closeDialog"
        :draggable="false"
        modal
        :closable="!isLoading"
    >
        <div class="flex flex-column gap-2 w-full">
            <span class="mb-1">Você deseja excluir esta esposição?</span>
            <div class="flex justify-content-end align-items-center mt-4">
                <Button @click="closeDialog" class="button-link mb-1" text label="Cancelar" />
                <Button class="ml-4 button-salvar" type="button" label="Confirmar" @click="excluir" :loading="isLoading" />
            </div>
        </div>
    </Dialog>
</template>
