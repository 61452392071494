<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';

const emit = defineEmits(['update:formData']);

const props = defineProps({
    formData: Object
});

const _form = computed({
    get: () => props.formData,
    set: (value) => emit('update:formData', value)
});

const opcoesTipoAplicacao = ref([
    { label: 'Atividade específica', value: 'atividade' },
    { label: 'Nova exposição na hierarquia', value: 'exposicao' }
]);
</script>
<template>
    <div class="grid">
        <div class="col-12 mt-1">
            <Dropdown
                v-model="_form.tipoAplicacao"
                :options="opcoesTipoAplicacao"
                optionLabel="label"
                optionValue="value"
                placeholder="Selecione"
                class="w-full"
            />
        </div>
    </div>
</template>
