<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import AppConfirmDialog from '../../../../components/AppConfirmDialog.vue';
import { useAlert } from '../../../../composables/useAlert';
import { getClientSesmt } from '../../../../services/http-sesmt';

import DialogVincularFuncionarios from './DialogVincularFuncionarios.vue';

const emit = defineEmits(['onCarregarListagem']);
const props = defineProps({
    loading: {
        type: Boolean,
        required: true
    },
    aplicAtividadesEspecificas: {
        type: Array
    },
    inventarioHierarquiaId: {
        type: Number
    },
    hierarquia: {
        type: Object
    },
    indexHierarquia: {
        type: Number
    }
});
const { alertSucess, alertError } = useAlert();
const showConfirmDialog = ref(false);

const actionItems = [
    {
        label: 'Editar',
        icon: 'pi pi-pencil',
        disabled: () => true

        // TODO:
        // FUTURAMENTE SERÁ IMPLEMENTADA UMA FUNÇÃO DE EDITAR
        // command: () => openDialogExposicao(record)
    },
    {
        label: 'Excluir',
        icon: 'pi pi-trash',
        disabled: () => {},
        command: () => {
            showConfirmDialog.value = true;
        }
    },
    {
        label: 'Vincular Funcionários',
        icon: 'pi pi-link',
        command: () => {
            openDialogVincularFuncionarios();
        }
    }
];
const dialogVincularFuncionarios = ref();
const linhaAtual = ref(null);
const menu = ref({});

const toggleMenu = (event, data) => {
    linhaAtual.value = data;
    menu.value?.toggle(event);
};

const openDialogVincularFuncionarios = () =>
    dialogVincularFuncionarios.value?.openDialog({
        hierarquiaId: linhaAtual.value?.hierarquiaId,
        aplicacaoAtividadeEspecificaId: linhaAtual.value?.id,
        funcionarios: getFuncionariosIdEName(),
        funcionariosNaoVinculados: linhaAtual.value?.funcionariosNaoEncontrados
    });

const getNomesFuncionariosFormatado = (aplicacaoAtividadesEspecificasFuncionarios) => {
    const maxFuncionariosExibidos = 3;
    const totalFuncionarios = aplicacaoAtividadesEspecificasFuncionarios.length;

    const funcionariosExibidos = aplicacaoAtividadesEspecificasFuncionarios
        .slice(0, maxFuncionariosExibidos)
        .map((aplicAtividadeEspecificaFuncionario) => aplicAtividadeEspecificaFuncionario.funcionario.name);

    const funcionariosRestantes = totalFuncionarios - maxFuncionariosExibidos;

    let resultado = funcionariosExibidos.join(', ');
    if (funcionariosRestantes > 0) {
        resultado += `, ${funcionariosRestantes}+`;
    }

    return resultado;
};

const getNomesFuncionarios = (aplicacaoAtividadesEspecificasFuncionarios) => {
    const nomesFuncionarios = aplicacaoAtividadesEspecificasFuncionarios.map(
        (aplicacaoAtividadesEspecificasFuncionarios) => aplicacaoAtividadesEspecificasFuncionarios.funcionario.name
    );
    return nomesFuncionarios.join(', ');
};

const excluirAplicAtividadeEspecifica = async () => {
    try {
        await getClientSesmt().delete(`aplicacao-pgr-elaboracao/excluir/aplic-atividades-especificas/${linhaAtual.value.id}`);
        const payload = {
            data: props.hierarquia,
            indexHierarquia: props.indexHierarquia
        };
        emit('onCarregarListagem', payload);
        alertSucess('Atividade especifica excluida com sucesso.');
    } catch (error) {
        const { message } = error?.response?.data;
        alertError(`Erro ao listar! ${message}`);
    } finally {
        showConfirmDialog.value = false;
    }
};

const getFuncionariosIdEName = () => {
    return linhaAtual.value.aplicacaoAtividadesEspecificasFuncionarios.map((aplicacaoAtividadesEspecificasFuncionarios) => {
        return {
            id: aplicacaoAtividadesEspecificasFuncionarios.funcionario.id,
            name: aplicacaoAtividadesEspecificasFuncionarios.funcionario.name
        };
    });
};

const onSuccessoVincular = () => {
    const payload = {
        data: props.hierarquia,
        indexHierarquia: props.indexHierarquia
    };
    emit('onCarregarListagem', payload);
};
</script>
<template>
    <DataTable dataKey="id" :loading="loading" :value="aplicAtividadesEspecificas" size="small">
        <template #empty> Sem atividades especificas</template>
        <template #loading>
            <AppLoadingWrapper />
        </template>
        <Column field="atividadeEspecifica.nome" header="Descricão" headerStyle="width: 25rem;" />
        <Column header="Funcionário">
            <template #body="{ data }">
                <div
                    class="overflow-hidden text-overflow-ellipsis"
                    v-tooltip.top="{
                        value: getNomesFuncionarios(data.aplicacaoAtividadesEspecificasFuncionarios),
                        pt: {
                            text: 'w-30rem'
                        }
                    }"
                >
                    {{ getNomesFuncionariosFormatado(data.aplicacaoAtividadesEspecificasFuncionarios) }}
                </div>
            </template>
        </Column>
        <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="{ data }">
                <i
                    v-if="data.riscoAplicado"
                    v-tooltip.top="{
                        value: 'Atividade específica com aplicação de risco'
                    }"
                    class="pi pi-exclamation-triangle text-orange-400 text-2xl"
                ></i>
            </template>
        </Column>
        <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="{ data }">
                <i
                    v-if="data.funcionariosNaoEncontrados?.length"
                    v-tooltip.top="{
                        value: 'Atividade específica com pendência de vínculo de funcionário'
                    }"
                    class="pi pi-exclamation-circle text-blue-400 text-2xl"
                ></i>
            </template>
        </Column>
        <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="{ data }">
                <Button class="p-button-text p-button-secondary" icon="pi pi-ellipsis-v" @click="toggleMenu($event, data)" />
            </template>
        </Column>
    </DataTable>
    <DialogVincularFuncionarios ref="dialogVincularFuncionarios" @onSuccessoVincular="onSuccessoVincular" />
    <Menu ref="menu" :model="actionItems" :popup="true" />
    <AppConfirmDialog
        v-if="showConfirmDialog"
        v-model:visible="showConfirmDialog"
        noIcon
        confirmHeader="Excluir vinculo da atividade especifica?"
        title="Deseja excluir o vinculo desta atividade especifica?"
        @onClose="showConfirmDialog = false"
        @onConfirm="excluirAplicAtividadeEspecifica"
    />
</template>
