<script setup>
import { defineProps, ref, computed } from 'vue';
const props = defineProps({
    descricaoRisco: String,
    nivelRisco: String,
    atividade: Boolean
});

const styleMap = ref({
    'Risco Baixo': '#419544',
    'Risco Médio': '#D5A326',
    'Risco Alto': '#D06900',
    'Risco Irrelevante': '#90CD93',
    'Risco Crítico': '#D32F2F',
    'Não identificado': '#757575'
});

const styleTag = computed(() => {
    return {
        'border-radius': '20px',
        border: `2px solid ${props.atividade ? '#D06900' : '#ededed'}`,
        background: 'transparent',
        color: '#000'
    };
});
</script>
<template>
    <Tag :style="styleTag" v-tooltip.top="nivelRisco ?? 'Não identificado'">
        <div class="flex items-center gap-2 px-1">
            <div
                class="elypse"
                :style="{
                    'background-color': styleMap[nivelRisco ?? 'Não identificado']
                }"
            ></div>
            <span class="text-base">{{ props.descricaoRisco }}</span>
        </div>
    </Tag>
</template>
<style lang="scss">
.elypse {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 6.5px;
}
</style>
