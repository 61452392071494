<script setup>
import { ref, defineExpose, defineEmits } from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import { getClientSesmt } from '../../../../services/http-sesmt';
import { useAlert } from '../../../../composables/useAlert';

import MultiselectFuncionario from './MultiselectFuncionario.vue';

const { alertErrorData } = useAlert();

const emit = defineEmits(['onSuccessoVincular']);

const isOpen = ref(false);
const funcionarioSelecionados = ref([]);
const loading = ref(false);
const loadingPendencia = ref(false);
const pendencia = ref(false);
const idHierarquia = ref();
const idAplicacaoAtividadeEspecifica = ref();
const funcionariosNaoEncontrados = ref([]);

const funcionariosVinculados = ref([]);
const confirm = useConfirm();

const removerFuncionario = (id) => {
    const index = funcionarioSelecionados.value.findIndex((item) => item.id === id);
    if (index !== -1) {
        funcionarioSelecionados.value.splice(index, 1);
    }
};

const resolverPendencia = async () => {
    try {
        loadingPendencia.value = true;
        await getClientSesmt().patch(
            `aplicacao-pgr-elaboracao/resolver-pendencia/atividade-especifica-funcionarios/${idAplicacaoAtividadeEspecifica.value}`
        );
        funcionariosNaoEncontrados.value = [];
    } catch (error) {
        alertErrorData(error, 'Não foi possível resolver pendência, por favor tente novamente.');
    } finally {
        loadingPendencia.value = false;
    }
};

const vincularFuncionarios = async () => {
    try {
        loading.value = true;
        await getClientSesmt().post('aplicacao-pgr-elaboracao/vincular/atividade-especifica-funcionarios', {
            funcionariosId: funcionarioSelecionados.value.map((p) => p.id),
            aplicacaoAtividadeEspecificaId: idAplicacaoAtividadeEspecifica.value
        });

        closeDialog();
        emit('onSuccessoVincular');
    } catch (error) {
        alertErrorData(error, 'Não foi possível vincular os funcionários, por favor tente novamente.');
    } finally {
        loading.value = false;
    }
};

const excluirPendencia = () =>
    confirm.require({
        message: 'Deseja realmente excluir a pendência de funcionário? Ao excluir irá sumir da tela e essa ação não poderá ser desfeita.',
        header: 'Confirmação!',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-text',
        rejectLabel: 'Cancelar',
        acceptLabel: 'Confirmar',
        accept: () => {
            pendencia.value = false;
            funcionariosNaoEncontrados.value = [];
        },
        reject: () => {}
    });

const openDialog = ({ hierarquiaId, aplicacaoAtividadeEspecificaId, funcionarios, funcionariosNaoVinculados }) => {
    funcionarioSelecionados.value = [];
    idHierarquia.value = hierarquiaId;

    idAplicacaoAtividadeEspecifica.value = aplicacaoAtividadeEspecificaId;
    funcionariosVinculados.value.push(...funcionarios);
    funcionarioSelecionados.value.push(...funcionariosVinculados.value);
    funcionariosNaoVinculados ? funcionariosNaoEncontrados.value.push(...funcionariosNaoVinculados) : [];

    if (funcionariosNaoEncontrados.value.length) {
        pendencia.value = true;
    }

    isOpen.value = true;
};

const closeDialog = () => {
    funcionarioSelecionados.value = [];
    funcionariosNaoEncontrados.value = [];
    funcionariosVinculados.value = [];
    pendencia.value = false;

    isOpen.value = false;
};

defineExpose({
    openDialog
});
</script>
<template>
    <Dialog v-model:visible="isOpen" :style="{ width: '800px' }" header="Vincular Funcionários" @hide="closeDialog" :draggable="false" modal>
        <div class="flex flex-column gap-2 w-full">
            <div class="flex flex-column gap-3">
                <div class="w-full flex flex-column gap-2">
                    <label for="funcionarios">Funcionarios(s) indicado(s)</label>
                    <MultiselectFuncionario id="funcionarios" v-model="funcionarioSelecionados" :hierarquiaId="idHierarquia" />
                </div>

                <DataTable
                    :value="funcionarioSelecionados"
                    class="mt-5"
                    dataKey="id"
                    lazy
                    :loading="loading"
                    loadingIcon="pi pi-spinner"
                    removableSort
                >
                    <template #empty>Nenhum funcionário vinculado.</template>

                    <Column field="name" header="Funcionário"> </Column>
                    <Column field="id" headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                        <template #body="{ data }">
                            <Button
                                icon="pi pi-trash"
                                severity="danger"
                                text
                                rounded
                                aria-label="Cancel"
                                @click="() => removerFuncionario(data.id)"
                            />
                        </template>
                    </Column>
                </DataTable>
            </div>

            <div class="mt-3" v-if="pendencia">
                <Panel>
                    <template #header>
                        <div class="flex justify-content-between w-full align-items-center">
                            <span class="font-bold">Funcionários pendentes de vinculação</span>

                            <Button
                                v-if="!funcionariosNaoEncontrados.length"
                                icon="pi pi-times"
                                text
                                severity="secondary"
                                @click="excluirPendencia"
                            />
                        </div>
                    </template>
                    <div>
                        <div>
                            <span v-for="funcionario in funcionariosNaoEncontrados" :key="funcionario">{{ funcionario }}</span>
                        </div>
                        <Message v-if="!funcionariosNaoEncontrados.length" :closable="false" severity="success">Pendência resolvida</Message>
                        <div class="w-full flex justify-content-end" v-else>
                            <Button :loading="loadingPendencia" label="Resolver pendência" text @click="resolverPendencia" />
                        </div>
                    </div>
                </Panel>
            </div>
            <div class="flex justify-content-end align-items-center mt-4">
                <Button @click="closeDialog" class="button-link mb-1" text label="Cancelar" />
                <Button class="ml-4 button-salvar" type="button" label="Salvar" @click="vincularFuncionarios" :loading="loading" />
            </div>
        </div>
        <ConfirmDialog></ConfirmDialog>
    </Dialog>
</template>
