<script setup>
import { defineProps, defineEmits, computed, ref, onBeforeMount } from 'vue';

import SesmtService from '../../../../services/SesmtService';

const service = ref();

const value = computed({
    get: () => props.modelValue,
    set: (value) => emits('update:modelValue', value)
});

const emits = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: Object
    },
    recordsPerPage: {
        type: Number,
        default: 20
    },

    filtrosExtras: {
        type: Object
    }
});

onBeforeMount(() => (service.value = new SesmtService('/riscos')));
</script>
<template>
    <AppInfinityDropdown
        ref="infintyDropdown"
        v-model="value"
        optionLabel="nome"
        :filterFields="['nome']"
        :service="service"
        :recordsPerPage="recordsPerPage"
        :filtrosExtras="filtrosExtras"
    />
</template>
